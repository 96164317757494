import { StyleProp, Tabs, TabsProps, TabsVariant } from '@mantine/core'
import { Property } from 'csstype'
import React, { ReactNode } from 'react'
import ReactTooltip from 'react-tooltip'
import classes from './Tabs.module.css'
import { cn } from '@/utils-v2/shadcn-utils'

type Tab = {
  value: string
  label: string
  count?: number
  disabled?: boolean
  icon?: any
  tooltip?: string
  className?: string
  flex?: StyleProp<Property.Flex<string | number>>
}

interface ITabs extends TabsProps {
  tabs: Tab[]
  children: ReactNode
  variant?: TabsVariant
}

const MantineTabs: React.FC<ITabs> = ({ tabs = [], variant = 'default', children, ...rest }) => (
  <Tabs
    defaultValue={tabs?.[0]?.value}
    variant={variant}
    autoContrast
    classNames={{ tab: classes.tab, list: variant === 'outline' && classes.outlineTabList }}
    color={variant === 'outline' ? 'gray.0' : 'black'}
    styles={{
      tab: {
        color: 'var(--mantine-color-gray-6)',
        '&:hover': {
          color: 'red'
        }
      }
    }}
    {...rest}
  >
    <Tabs.List>
      {tabs.map((tab) => (
        <Tabs.Tab
          key={tab.value}
          value={tab.value}
          data-for={tab.value}
          data-tip={tab.value}
          disabled={tab.disabled}
          leftSection={tab?.icon}
          rightSection={tab?.count > 0 && <Count count={tab.count} />}
          fz={rest?.fz}
          flex={tab?.flex}
          className={cn([
            "[&[data-active='true']]:!text-black-0 [&[data-active='true']]:font-semibold",
            tab?.className
          ])}
        >
          {tab.label}
          {tab?.tooltip && (
            <ReactTooltip
              id={tab.value}
              borderColor="#eaeaec"
              border
              backgroundColor="white"
              textColor="#111111"
              effect="solid"
              className="!opacity-100 !rounded-lg"
            >
              {tab.tooltip}
            </ReactTooltip>
          )}
        </Tabs.Tab>
      ))}
    </Tabs.List>

    {children}
  </Tabs>
)

export default MantineTabs

const Count = ({ count }) => (
  <div className="px-2 h-6 flex justify-center items-center rounded bg-blanca-300 ml-2">{count}</div>
)
