import MultiSelectCheckbox from '@/components-v2/molecules/MultiSelectCheckBox'
import { useUpdateWalletMutation } from '@/slice/wallets/wallet-api'
import { IWalletParams } from '@/slice/wallets/wallet-types'
import { useOrganizationId } from '@/utils/getOrganizationId'
import { debounce } from 'lodash'
import React, { Dispatch, SetStateAction } from 'react'
import WalletTable from './WalletTable/WalletTable'
import { Input } from '@mantine/core'
import { IconSearch } from '@tabler/icons-react'
import { useWalletSync } from '@/hooks-v2/useWalletSync'
import ChainFilters from '@/components-v2/molecules/ChainFilters/ChainFilters'

interface WalletListProps {
  data: any[]
  loading?: boolean
  pagination: {
    pageIndex: number
    pageSize: number
  }
  setPagination: Dispatch<
    SetStateAction<{
      pageIndex: number
      pageSize: number
    }>
  >
  totalItems: number
  search: string
  onChangeSearch: (e) => void
  filter: IWalletParams
  setFilter: (filter: IWalletParams) => void
  groups: any[]
  onChainFilter: (chain) => void
  supportedChains: any[]
  onSelectAllChains: () => void
  areAllChainsSelected?: boolean
}

const WalletList: React.FC<WalletListProps> = ({
  data,
  loading,
  groups,
  pagination,
  setPagination,
  filter,
  setFilter,
  totalItems,
  search,
  onChangeSearch,
  supportedChains,
  onChainFilter,
  onSelectAllChains,
  areAllChainsSelected
}) => {
  const organizationId = useOrganizationId()
  const { checkWalletSync } = useWalletSync({
    organisationId: organizationId
  })
  const [editWallet, editWalletResult] = useUpdateWalletMutation()

  const onChangeGroupFilter = (value) => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
    setFilter({
      ...filter,
      walletGroupIds: value
    })
  }
  const onClearGroupFilter = () => {
    setPagination((prev) => ({ ...prev, pageIndex: 0 }))
    setFilter({
      ...filter,
      walletGroupIds: []
    })
  }

  const handleChangeGroup = (row, groupId) => {
    if (groupId !== row.group.id) {
      editWallet({
        orgId: organizationId,
        payload: { name: row.name, flagged: row.flag, walletGroupId: groupId },
        id: row.id
      })
      checkWalletSync()
    }
  }

  const groupOptions = groups?.map((item) => ({
    label: item.name,
    value: item.id,
    name: item.name
  }))

  return (
    <div className="mt-6 lg:mt-0">
      <div className="flex mb-3 justify-center lg:hidden">
        <ChainFilters values={filter?.blockchainIds} onChange={onChainFilter} onClear={onSelectAllChains} />
      </div>
      <div className="flex items-center justify-between mb-3 lg:hidden">
        <Input
          w="20%"
          miw={280}
          value={search}
          leftSection={<IconSearch size={14} />}
          placeholder="Search by wallet name or address"
          onChange={onChangeSearch}
        />
        <div className="w-full max-w-[250px]">
          <MultiSelectCheckbox
            data={groupOptions}
            placeholder="All Wallet Groups"
            name="Wallet Group"
            onChange={debounce(onChangeGroupFilter, 300)}
            onClear={onClearGroupFilter}
          />
        </div>
      </div>
      <WalletTable
        data={data}
        loading={loading}
        totalItems={totalItems}
        pagination={pagination}
        setPagination={setPagination}
        groupOptions={groupOptions}
        chainsFilter={filter?.blockchainIds}
        onChangeGroup={handleChangeGroup}
      />
    </div>
  )
}

export default WalletList
