import { useWagmiConfig } from '@/hooks-v2/config/useWagmiConfig'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'

const queryClient = new QueryClient()

const CustomWagmiProvider = ({ children }) => {
  const config = useWagmiConfig()

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}

export default CustomWagmiProvider
