import ChainList from '@/components-v2/molecules/ChainList/ChainList'
import WalletAddress from '@/components-v2/molecules/WalletAddressCopy/WalletAddress'
import FlagIcon from '@/public/svg/RedFlag.svg'
import { Avatar, Flex, Group, Stack, Text } from '@mantine/core'
import Image from 'next/image'
import Safe from '@/public/svg/logos/safe-logo.svg'
import WalletIcon from '@/public/svg/wallet-03-white.svg'

const NameCell = ({ data, isWalletSyncing }) => (
  <Flex gap={12}>
    <div className="lg:block hidden mt-1">
      {data.type?.toLowerCase() === 'safe' ? (
        <Image src={Safe} alt="safe" width={32} height={32} />
      ) : (
        <Avatar size={26} variant="filled" radius="xl" color="rgba(0, 0, 0, 1)">
          <Image src={WalletIcon} alt="wallet" width={12} height={12} />
        </Avatar>
      )}
    </div>
    <Stack className="w-full" gap={4}>
      <Group gap={6}>
        {data.flag && <Image src={FlagIcon} alt="flag" width={12} height={12} />}
        <Text truncate className="flex-1">
          {data.title}
        </Text>
      </Group>
      <WalletAddress split={5} address={data?.address} variant="caption">
        <WalletAddress.Link address={data?.address} options={data?.chains} />
        <WalletAddress.Copy address={data?.address} />
        <WalletAddress.QR address={data?.address} blockchainIds={data?.chains?.map((item) => item.id)} />
      </WalletAddress>
      <div className="lg:block hidden">
        <ChainList chains={data?.chains} />
      </div>
    </Stack>
  </Flex>
)

export default NameCell
